import { switcherSdk } from "utils/switcher-sdk";
import { client } from "api/client";
import mean from "lodash/mean";
import { StripePrice } from "@switcherstudio/switcher-api-client";
const switcherDashboardGtmId = import.meta.env.VITE_GTM_ID;
const switcherDashboardGtmEnvString = import.meta.env.VITE_GTM_ENV_STRING;
declare global {
    interface Window {
        dataLayer: any;
    }
}

export function bootSegment(opts?: SegmentAnalytics.SegmentOpts) {
    const segmentOptions = filterSegmentIntegrations(opts);
    analytics.load(import.meta.env.VITE_SEGMENT_WRITEKEY, segmentOptions);
    analytics.ready(function () {
        if (!window.dataLayer) {
            // Init Google Tag Manager
            /* eslint-disable */
            const initGTM = function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    "gtm.start": new Date().getTime(),
                    event: "gtm.js"
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src =
                    "https://www.googletagmanager.com/gtm.js?id=" +
                    i +
                    dl +
                    (switcherDashboardGtmEnvString ?? "");
                f.parentNode.insertBefore(j, f);
            };
            initGTM(
                window,
                document,
                "script",
                "dataLayer",
                switcherDashboardGtmId
            );
            /* eslint-enable */
        }
    });
}

export function trackEvent(event: string, properties?: any, options?: any) {
    if (window.dataLayer) {
        // Track GTM Custom Event
        window.dataLayer.push({ "event": event, ...properties });
    }

    // Track Segment Event
    const promise = new Promise<void>((resolve) => {
        analytics.track(event, properties, options, () => resolve());
    });

    return promise;
}

export function trackPageView(url: string) {
    if (url !== "/") {
        let name = url;
        const title = parsePath(url);
        if (title.toLowerCase() === "authorize") {
            // or any other routes we want to strip query params out of
            name = name.split("?")[0];
        }
        analytics.page(url, {
            name,
            title
        });
    }
}

function filterSegmentIntegrations(opts: SegmentAnalytics.SegmentOpts = {}) {
    const isDashboard: boolean = switcherSdk.clientId === "Generic";
    const blockedIntegrations: string[] = isDashboard
        ? []
        : ["Facebook Pixel", "Bing Ads", "Google Ads", "ProfitWell"];
    const blockedIntegrationOptions: Record<string, boolean> =
        blockedIntegrations.reduce<Record<string, boolean>>(
            (integrations, blockedIntegration) => {
                integrations[blockedIntegration] = false;
                return integrations;
            },
            {}
        );

    opts.integrations = {
        ...opts.integrations,
        ...blockedIntegrationOptions
    };

    return opts;
}

function parsePath(path: string) {
    let pieces = path.replace(/\//g, "").split("-");
    pieces = pieces.map((p) => p.charAt(0).toUpperCase() + p.slice(1));

    let final = pieces.join(" ");
    return final;
}

export const getMeanLTV = async () => {
    const plans = await client.metrics_GetPlansLtv();
    return mean(plans.filter((p) => p.Ltv > 0).map((p) => p.Ltv)).toFixed(
        2
    ) as unknown as number;
};

export const getPlanLTV = async (planId: string) => {
    const plans = await client.metrics_GetPlansLtv();
    return plans.filter((p) => p.PlanId === planId)?.[0];
};

// google analytics report conversion
export const getPredictedPlanValue = async (plan?: StripePrice) =>
    plan ? (await getPlanLTV(plan.Id))?.Ltv : await getMeanLTV();

export const trackConversion = async (
    isTrialing: boolean,
    clientId: string,
    plan?: StripePrice,
    userId?: string
) => {
    try {
        const stripeCustomer = await client.stripe_GetCustomer();
        const stripeSub = stripeCustomer?.StripeSubscriptions?.[0];
        const predictedValue = await getPredictedPlanValue(plan);
        const planStartDate = plan?.CreatedAt ?? new Date().toISOString();
        const planEndDate = stripeSub?.ActiveUntil;
        if (plan) {
            if (isTrialing) {
                // is trial plan
                trackEvent("Trial Started", {
                    source: clientId,
                    currency: "USD",
                    predicted_ltv: predictedValue,
                    value: predictedValue,
                    priceId: plan.Id,
                    trial_plan_name: plan.Name,
                    trial_start_date: planStartDate,
                    trial_end_date: planEndDate,
                    user_id: userId
                });
            } else {
                if (plan.IsRecurring) {
                    // is paid subscription plan
                    trackEvent("Signed Up", {
                        source: clientId,
                        currency: "USD",
                        predicted_ltv: predictedValue,
                        value: predictedValue,
                        priceId: plan?.Id
                    });
                } else {
                    // is one time pass
                    trackEvent("Order Completed", {
                        source: clientId,
                        currency: "USD",
                        value: plan?.Amount,
                        total: plan?.Amount,
                        priceId: plan?.Id
                    });
                }
            }
        } else {
            // is trial plan - dts set to false
            trackEvent("Trial Started", {
                source: clientId,
                currency: "USD",
                predicted_ltv: predictedValue,
                value: predictedValue,
                trial_plan_name: "Trial",
                trial_start_date: planStartDate,
                trial_end_date: planEndDate,
                user_id: userId
            });
        }
    } catch {}
};
