import { Switch } from "components/utility/Switch";
import styles from "./index.module.scss";
import { Case } from "components/utility/Case";

export type SpinnerVariantOptions = "ring";

// SVG Source - https://github.com/n3r4zzurr0/svg-spinners/tree/main
export interface SpinnerProps {
    variant?: SpinnerVariantOptions;
    size?: number;
    stroke?: string;
    strokeWidth?: number;
    alignment?: "center" | "left" | "right";
}
export const Spinner = ({
    variant = "ring",
    size = 64,
    stroke = "#000",
    strokeWidth = 1,
    alignment = "center"
}: SpinnerProps) => {
    return (
        <div className={`spinner ${styles["container"]}  ${styles[alignment]}`}>
            <svg
                stroke={stroke}
                width={size}
                height={size}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Switch test={variant}>
                    <Case<SpinnerVariantOptions> value="ring">
                        <g className={styles["ring"]}>
                            <circle
                                cx="12"
                                cy="12"
                                r="9.5"
                                fill="none"
                                strokeWidth={strokeWidth}
                            ></circle>
                        </g>
                    </Case>
                    <></>
                </Switch>
            </svg>
        </div>
    );
};
