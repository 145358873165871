import { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useStripeAccountInfo } from "hooks/useStripeAccountInfo";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";
import { PricingModalTypes } from "components/modal/GatedContentModal";
import { AppDispatch } from "store/store";
import { postEvents } from "store/events/thunks";
import rollbar from "helpers/rollbar";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    buildPriceCreateObj,
    buildPricesArray
} from "components/modal/GatedContentModal/helpers";
import { v4 as uuidv4 } from "uuid";
import { displayAmount } from "helpers/stripe";
import { GatedContentPassFormProps } from "components/modal/GatedContentModal";
import {
    CatalogResponse,
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductPricesBindingModel
} from "@switcherstudio/switcher-api-client";
import { useGetStripeAccounts } from "hooks/useGetStripeAccounts";
import { exists } from "helpers/booleans";
import { sortByDate } from "helpers/time";
import type { StringDictionary } from "types";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { AttentionModal } from "components/modal/AttentionModal";

export interface useCatalogSubscriptionPassProps {
    catalog: CatalogResponse;
}

export const useCatalogSubscriptionPass = ({
    catalog
}: useCatalogSubscriptionPassProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const [priceErrors, setPriceErrors] = useState<StringDictionary>({});
    const { t } = useTranslation("subscription-page");

    // Get the Stripe info
    const { details, gatedContentStatus, loading } = useStripeAccountInfo({
        expandDetails: true
    });
    const { accounts } = useGetStripeAccounts();
    const { isSetup } = useGetStripeConnectLink();

    /* Define (alias) dispatchApiRequest callbacks for API calls */

    // POST Products
    const { dispatchApiRequest: postProduct } = useSwitcherClient(
        (client) => client.creatorProducts_Create
    );

    // POST Prices
    const { dispatchApiRequest: postProductPrices } = useSwitcherClient(
        (client) => client.creatorProductPrices_Create
    );

    // PUT Prices
    const { dispatchApiRequest: putProductPrices } = useSwitcherClient(
        (client) => client.creatorProductPrices_Update
    );

    // POST Entitlements
    const { dispatchApiRequest: postEntitlement } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_Create
    );

    // GET Entitlements and Prices by Catalog ID (to refetch after updates)
    const {
        dispatchApiRequest: getEntitlementAndPrices,
        data: entitlementAndPrices
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_GetByCatalogId,
        {
            requestImmediately: true,
            args: [catalog?.Details?.Id, true]
        }
    );

    const { dispatchApiRequest: removeActiveSubscriptions } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_CancelByProductId,
        {
            requestImmediately: false,
            onError: (e) => {
                rollbar.error("Error removing active subscriptions", e, {
                    productId:
                        entitlementAndPrices?.ProductEntitlements?.[0]
                            ?.ProductId
                });
            }
        }
    );

    // Manage state for local form values
    const [createOrUpdate, setCreateOrUpdate] = useState<PricingModalTypes>(
        PricingModalTypes.Create
    );
    const [values, setValues] = useState<GatedContentPassFormProps>(null);
    const [oldProduct, setOldProduct] = useState<any>(null);
    const [oldRecurringMonthlyPrice, setOldRecurringMonthlyPrice] =
        useState<any>(null);
    const [oldRecurringAnnualPrice, setOldRecurringAnnualPrice] =
        useState<any>(null);
    const [oldOneTimePrice, setOldOneTimePrice] = useState<any>(null);
    const [initialSelectedPassType, setInitialSelectedPassType] = useState<
        "recurring" | "one-time"
    >(values?.selectedPassType ?? "recurring");

    const format = (amount: number) => {
        return displayAmount(amount, {
            signed: false,
            compact: false,
            roundUp: false,
            useGrouping: false
        });
    };

    const catalogHasPrice = useMemo(() => {
        if (loading) return undefined;

        const hasRecurringPriceSelected =
            values?.recurringAnnualPriceSelected ||
            values?.recurringMonthlyPriceSelected;
        const hasOneTimePriceSelected = values?.selectedPassType === "one-time";

        if (
            values?.selectedPassType === "recurring" &&
            !hasRecurringPriceSelected
        ) {
            return false;
        }

        return hasRecurringPriceSelected || hasOneTimePriceSelected;
    }, [loading, values]);

    useEffect(() => {
        if (!entitlementAndPrices) return;
        const oldProduct =
            entitlementAndPrices?.ProductEntitlements[0]?.Product;

        // Get the most recent values for recurring monthly and annual prices
        const oldRecurringMonthlyPrice = oldProduct?.Prices?.filter(
            (p) => p.RecurringInterval === "month"
        )?.sort((a, b) =>
            sortByDate(a.InsertedAt, b.InsertedAt, { descending: true })
        )?.[0];

        const oldRecurringAnnualPrice = oldProduct?.Prices?.filter(
            (p) => p.RecurringInterval === "year"
        )?.sort((a, b) =>
            sortByDate(a.InsertedAt, b.InsertedAt, { descending: true })
        )?.[0];

        const oldOneTimePrice = oldProduct?.Prices?.filter(
            (p) => !p.RecurringInterval
        )?.sort((a, b) =>
            sortByDate(a.InsertedAt, b.InsertedAt, { descending: true })
        )?.[0];

        const sortedActivePrices = oldProduct?.Prices?.filter(
            (p) => p.Active
        )?.sort((a, b) =>
            sortByDate(a.UpdatedAt, b.UpdatedAt, { descending: true })
        );

        const initialPassType: "recurring" | "one-time" =
            sortedActivePrices?.length > 0
                ? sortedActivePrices[0]?.RecurringInterval
                    ? "recurring"
                    : "one-time"
                : "recurring";

        // Set local state
        if (exists(oldProduct)) setCreateOrUpdate(PricingModalTypes.Update);
        setOldProduct(oldProduct);
        setOldRecurringMonthlyPrice(oldRecurringMonthlyPrice);
        setOldRecurringAnnualPrice(oldRecurringAnnualPrice);
        setOldOneTimePrice(oldOneTimePrice);
        setInitialSelectedPassType(initialPassType);

        // Set the values for the form
        const isMonthlyActive = oldRecurringMonthlyPrice?.Active || false;
        const isAnnualActive = oldRecurringAnnualPrice?.Active || false;
        const isOneTimeActive = oldOneTimePrice?.Active || false;
        const oneTimeDurationSelected =
            oldOneTimePrice?.Active && oldOneTimePrice?.EntitledDuration > 0;

        const oneTimeDuration =
            oldOneTimePrice?.EntitledDuration?.toString() || null;
        const oneTimeDurationUnits =
            oldOneTimePrice?.EntitledDurationUnit || null;
        const recurringMonthlyPrice = oldRecurringMonthlyPrice
            ? format(oldRecurringMonthlyPrice?.Amount)
            : "10.00";
        const recurringAnnualPrice = oldRecurringAnnualPrice
            ? format(oldRecurringAnnualPrice?.Amount)
            : "100.00";
        const oneTimePrice = oldOneTimePrice
            ? format(oldOneTimePrice?.Amount)
            : "2.00";

        const newValues: GatedContentPassFormProps = {
            name: "Catalog Pass",
            description: "",
            selectedPassType: initialSelectedPassType,
            recurringMonthlyPriceSelected: isMonthlyActive && !isOneTimeActive,
            recurringAnnualPriceSelected: isAnnualActive && !isOneTimeActive,
            oneTimePriceSelected:
                isOneTimeActive && (!isMonthlyActive || !isAnnualActive),
            oneTimePrice,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            recurringApplySubscriptionUpdatesNewOnly: true,
            oneTimeDurationSelected,
            oneTimeDuration,
            oneTimeDurationUnits
        };

        setValues(newValues);
    }, [entitlementAndPrices, initialSelectedPassType]);

    const catalogPassHasNoChanges = useMemo(() => {
        const passTypeHasNoChange =
            values?.selectedPassType === initialSelectedPassType;
        const monthlyActiveMatch =
            oldRecurringMonthlyPrice?.Active ===
            values?.recurringMonthlyPriceSelected;
        const monthlyAmountMatch =
            oldRecurringMonthlyPrice?.Amount ===
            parseFloat(values?.recurringMonthlyPrice) * 100;
        const annualActiveMatch =
            oldRecurringAnnualPrice?.Active ===
            values?.recurringAnnualPriceSelected;
        const annualAmountMatch =
            oldRecurringAnnualPrice?.Amount ===
            parseFloat(values?.recurringAnnualPrice) * 100;
        const oneTimeActiveMatch =
            oldOneTimePrice?.Active === values?.oneTimePriceSelected;
        const oneTimeAmountMatch =
            oldOneTimePrice?.Amount === parseFloat(values?.oneTimePrice) * 100;
        const oneTimeDurationSelectedMatch =
            oldOneTimePrice?.EntitledDuration > 0 ===
            values?.oneTimeDurationSelected;
        const oneTimeDurationMatch =
            parseInt(oldOneTimePrice?.EntitledDuration) ===
                parseInt(values?.oneTimeDuration) ||
            null === values?.oneTimeDuration;
        const oneTimeDurationUnitsMatch =
            oldOneTimePrice?.EntitledDurationUnit ===
            values?.oneTimeDurationUnits;

        const recurringPassHasNoChanges =
            (monthlyActiveMatch &&
                annualActiveMatch &&
                annualActiveMatch &&
                monthlyAmountMatch &&
                annualAmountMatch) ||
            (!values?.recurringMonthlyPriceSelected &&
                !values?.recurringAnnualPriceSelected &&
                !oldRecurringMonthlyPrice?.Active &&
                !oldRecurringAnnualPrice?.Active);

        const oneTimePassHasNoChanges =
            oneTimeActiveMatch &&
            oneTimeAmountMatch &&
            oneTimeDurationSelectedMatch &&
            oneTimeDurationMatch &&
            oneTimeDurationUnitsMatch;

        return (
            recurringPassHasNoChanges &&
            oneTimePassHasNoChanges &&
            passTypeHasNoChange
        );
    }, [
        values?.selectedPassType,
        values?.recurringMonthlyPriceSelected,
        values?.recurringMonthlyPrice,
        values?.recurringAnnualPriceSelected,
        values?.recurringAnnualPrice,
        values?.oneTimePriceSelected,
        values?.oneTimePrice,
        values?.oneTimeDurationSelected,
        values?.oneTimeDuration,
        values?.oneTimeDurationUnits,
        initialSelectedPassType,
        oldRecurringMonthlyPrice?.Active,
        oldRecurringMonthlyPrice?.Amount,
        oldRecurringAnnualPrice?.Active,
        oldRecurringAnnualPrice?.Amount,
        oldOneTimePrice?.Active,
        oldOneTimePrice?.Amount,
        oldOneTimePrice?.EntitledDuration,
        oldOneTimePrice?.EntitledDurationUnit
    ]);

    const updatePrices = useCallback(async () => {
        // Destructure form values
        const {
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            oneTimePrice,
            oneTimeDurationSelected,
            oneTimeDuration,
            oneTimeDurationUnits,
            recurringApplySubscriptionUpdatesNewOnly,
            selectedPassType
        } = values;

        // Build the array for the PUT request
        const pricesArray = buildPricesArray({
            name: "Catalog Pass",
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            oldProduct,
            oldRecurringMonthlyPrice,
            oldRecurringAnnualPrice,
            oldOneTimePrice,
            selectedPassType,
            oneTimePrice,
            oneTimeDurationSelected,
            oneTimeDuration,
            oneTimeDurationUnits
        });

        try {
            await putProductPrices([
                accounts[0]?.Id,
                oldProduct?.Id,
                {
                    Prices: pricesArray,
                    UpdateExistingCustomersToPrice:
                        !recurringApplySubscriptionUpdatesNewOnly
                }
            ]);
        } catch (e) {
            rollbar.error("Error updating catalog pass", e);
        } finally {
            getEntitlementAndPrices();
        }
    }, [
        oldProduct,
        oldRecurringMonthlyPrice,
        oldRecurringAnnualPrice,
        oldOneTimePrice,
        accounts,
        getEntitlementAndPrices,
        putProductPrices,
        values
    ]);

    const handleSubmit = useCallback(async () => {
        const {
            name,
            selectedPassType,
            oneTimePrice,
            oneTimeDurationSelected,
            oneTimeDuration,
            oneTimeDurationUnits,
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice
        } = values;

        if (Object.keys(priceErrors).length > 0) {
            return;
        }

        if (createOrUpdate === PricingModalTypes.Create) {
            const getPrices = (
                productId: string
            ): CreatorProductPricesBindingModel[] => {
                const prices = [];
                if (selectedPassType === "one-time") {
                    prices.push(
                        buildPriceCreateObj(
                            name,
                            productId,
                            oneTimePrice,
                            false,
                            null,
                            oneTimeDurationSelected ? oneTimeDuration : null,
                            oneTimeDurationSelected
                                ? oneTimeDurationUnits
                                : null
                        )
                    );
                } else {
                    if (recurringMonthlyPriceSelected) {
                        prices.push(
                            buildPriceCreateObj(
                                name,
                                productId,
                                recurringMonthlyPrice,
                                true,
                                "month"
                            )
                        );
                    }

                    if (recurringAnnualPriceSelected) {
                        prices.push(
                            buildPriceCreateObj(
                                name,
                                productId,
                                recurringAnnualPrice,
                                true,
                                "year"
                            )
                        );
                    }
                }
                return prices;
            };

            try {
                const res = await postProduct([
                    accounts[0]?.Id,
                    {
                        Products: [
                            {
                                Id: uuidv4(),
                                Name: "Catalog Pass",
                                StripeAccountId: accounts[0]?.Id,
                                IsActive: true
                            }
                        ]
                    }
                ]);

                await postProductPrices([
                    accounts?.[0]?.Id,
                    res?.Products?.[0]?.Id,
                    {
                        Prices: getPrices(res?.Products?.[0]?.Id)
                    }
                ]);

                await postEntitlement([
                    {
                        ProductEntitlements: [
                            {
                                ProductId: res?.Products[0]?.Id,
                                Discriminator:
                                    CreatorProductEntitlementsBindingModelDiscriminator._3,
                                CatalogId: catalog?.Details?.Id
                            }
                        ]
                    }
                ]);

                dispatch(postEvents({ "created-pass": true }));
            } catch (e) {
                rollbar.error("Error creating catalog pass", e);
            }

            await updatePrices();
        } else if (createOrUpdate === PricingModalTypes.Update) {
            // If there are no changes to the recurring prices, return
            if (catalogPassHasNoChanges) {
                getEntitlementAndPrices();
                return;
            }

            // If the user has active subscriptions and is trying to cancel both recurring prices, show a warning modal
            const subscriberCount = oldProduct?.ActiveSubscriptions;
            if (
                Number.isInteger(subscriberCount) &&
                subscriberCount > 0 &&
                !recurringMonthlyPriceSelected &&
                !recurringAnnualPriceSelected
            ) {
                dispatch(
                    setActiveModal({
                        id: Modals.AttentionModal,
                        type: Modals.AttentionModal,
                        component: (
                            <AttentionModal
                                isOpen={true}
                                setIsOpen={() => dispatch(closeCurrentModal())}
                                handleCancel={() =>
                                    dispatch(closeCurrentModal())
                                }
                                hasContinueButton={true}
                                handleContinue={async () => {
                                    postEntitlement([
                                        {
                                            ProductEntitlements: [
                                                {
                                                    ProductId: oldProduct.Id,
                                                    Discriminator:
                                                        CreatorProductEntitlementsBindingModelDiscriminator._3,
                                                    CatalogId:
                                                        catalog?.Details?.Id
                                                }
                                            ]
                                        }
                                    ]);

                                    updatePrices();
                                    await removeActiveSubscriptions([
                                        entitlementAndPrices
                                            ?.ProductEntitlements?.[0]
                                            ?.ProductId
                                    ]);

                                    dispatch(closeCurrentModal());
                                }}
                                continueText={t("buttons:continue")}
                            >
                                <p>{t("catalog-cancellation-warning")}</p>
                            </AttentionModal>
                        )
                    })
                );
            } else {
                await postEntitlement([
                    {
                        ProductEntitlements: [
                            {
                                ProductId: oldProduct.Id,
                                Discriminator:
                                    CreatorProductEntitlementsBindingModelDiscriminator._3,
                                CatalogId: catalog?.Details?.Id
                            }
                        ]
                    }
                ]);

                await updatePrices();
            }
        }
    }, [
        values,
        priceErrors,
        createOrUpdate,
        updatePrices,
        postProduct,
        accounts,
        postProductPrices,
        postEntitlement,
        catalog?.Details?.Id,
        dispatch,
        catalogPassHasNoChanges,
        oldProduct?.ActiveSubscriptions,
        oldProduct?.Id,
        getEntitlementAndPrices,
        t,
        removeActiveSubscriptions,
        entitlementAndPrices?.ProductEntitlements
    ]);

    const validateFields = useCallback(
        ({
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringAnnualPrice,
            recurringMonthlyPrice
        }: GatedContentPassFormProps) => {
            let errors: StringDictionary = {};
            const validatePrice = (id: string, price: string) => {
                const baseError = t(
                    "gated-content-modal:errors:price-min-error"
                );
                if (!price) {
                    errors[id] = t("gated-content-modal:errors:price-error");
                }

                const minPrices = {
                    recurringAnnualPrice: import.meta.env
                        .VITE_MINIMUM_ANNUAL_PRICE,
                    recurringMonthlyPrice: import.meta.env
                        .VITE_MINIMUM_MONTHLY_PRICE,
                    oneTimePrice: import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE
                };

                if (parseFloat(price) < parseFloat(minPrices[id])) {
                    errors[id] = `${baseError} $${minPrices[id]}`;
                }
            };

            if (recurringMonthlyPriceSelected) {
                validatePrice("recurringMonthlyPrice", recurringMonthlyPrice);
            }
            if (recurringAnnualPriceSelected) {
                validatePrice("recurringAnnualPrice", recurringAnnualPrice);
            }

            setPriceErrors(errors);
            return errors;
        },
        [t]
    );

    const handlePriceChange = useCallback(
        (key: string, val: any) => {
            const newValues = { ...values };

            if (key === "selectedPassType" && val === "one-time") {
                newValues.recurringMonthlyPriceSelected = false;
                newValues.recurringAnnualPriceSelected = false;
            } else if (key === "selectedPassType" && val === "recurring") {
                newValues.oneTimeDurationSelected = false;
                newValues.oneTimeDuration = null;
                newValues.oneTimeDurationUnits = null;
            } else if (key === "oneTimeDurationSelected") {
                newValues.selectedPassType = "one-time";
            }

            setValues({ ...newValues, [key]: val });
            validateFields({ ...newValues, [key]: val });
        },
        [values, validateFields, setValues]
    );

    return {
        values,
        priceErrors,
        handlePriceChange,
        handleSubmit,
        catalogPassHasNoChanges,
        loading,
        details,
        gatedContentStatus,
        isSetup,
        createOrUpdate,
        entitlementAndPrices,
        /** Given catalog has monthly or annual price or one-time price */
        catalogHasPrice
    };
};
