import {
    PlayerDetailsResponse,
    PlayerDetailsResponseAspectRatio,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";
import { updateKey } from "../objects";

export const mapPlayerDetailsResponseResponseToVideoPlayer = (
    input: PlayerDetailsResponse
): VideoPlayer => {
    input.AspectRatio = PlayerDetailsResponseAspectRatio.NineBySixteen;
    return updateKey(
        input,
        "IdleState",
        "PlayerDetailsResponseIdleState"
    ) as unknown as VideoPlayer;
};
