import { useTranslation } from "react-i18next";
import { useGetStripeAccounts } from "hooks/useGetStripeAccounts";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import mpstyles from "./index.module.scss";
import { CreatorProduct } from "@switcherstudio/switcher-api-client";

interface ActiveSubscribersTableProps {
    creatorProduct: CreatorProduct;
}

export const ActiveSubscribersTable: React.FC<ActiveSubscribersTableProps> = ({
    creatorProduct
}) => {
    const { t } = useTranslation();

    const { accounts } = useGetStripeAccounts();
    const stripeAccountId = accounts?.[0]?.Id;

    const { data } = useSwitcherClient(
        (client) =>
            client.creatorProducts_GetProductActiveSubscriberCountByPrice,
        {
            requestImmediately: true,
            args: [stripeAccountId, creatorProduct?.Id]
        }
    );

    const priceSubs = data?.PriceSubscriptions ?? [];

    const { monthly, annual } = priceSubs.reduce(
        (acc, { Interval, Subscriptions }) => {
            if (Interval === "month") {
                acc.monthly += Subscriptions;
            } else if (Interval === "year") {
                acc.annual += Subscriptions;
            }
            return acc;
        },
        { monthly: 0, annual: 0 }
    );

    if (!stripeAccountId || !creatorProduct?.Id) {
        return null;
    }

    const purchases = creatorProduct.Purchases ?? 0;

    return (
        <table className={mpstyles.subsTable}>
            <thead>
                <tr>
                    <th>{t("subscription:active-monthly-subs")}</th>
                    <th>{t("subscription:active-annual-subs")}</th>
                    <th>{t("subscription:one-time-purchases")}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{monthly}</td>
                    <td>{annual}</td>
                    <td>{purchases}</td>
                </tr>
            </tbody>
        </table>
    );
};
