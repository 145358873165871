import {
    CreatorProduct,
    CreatorProductPrice
} from "@switcherstudio/switcher-api-client";
import { useDispatch } from "react-redux";
import { useSwitcherClient } from "./useSwitcherClient";
import { addNotification } from "../store/notification/slice";
import { NotificationType } from "../store/notification/types";
import { useTranslation } from "react-i18next";
import { useState, useMemo, useEffect } from "react";

interface UseGetCreatorProductsV2Props {
    requestImmediately: boolean;
    stripeAccountLoading: boolean;
    stripeAccountId: string;
}

interface ExtendedCreatorProduct extends CreatorProduct {
    Prices: CreatorProductPrice[];
}

/**
 * Retrieves creator products and prices for a given stripe account.
 * NOTE: This should ONLY be used from within the `useUserStripeData` hook!
 */
export const useGetCreatorProductsV2 = ({
    requestImmediately = true,
    stripeAccountLoading,
    stripeAccountId
}: UseGetCreatorProductsV2Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [prices, setPrices] = useState<CreatorProductPrice[]>([]);
    const [productSubscribers, setProductSubscribers] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(
        stripeAccountLoading && requestImmediately
    );
    const [preparedProducts, setPreparedProducts] = useState<CreatorProduct[]>(
        []
    );

    const { dispatchApiRequest: getProducts } = useSwitcherClient(
        (client) => client.creatorProducts_Get,
        {
            args: [stripeAccountId, true, true, true],
            onSuccess: async (data) => {
                const products = await prepareProductsAndPricing(
                    data?.Products?.filter((p) => p.IsActive)
                );

                setPreparedProducts(products);
            },
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:product-price-retrieval-error")
                    })
                );
            }
        }
    );

    const { dispatchApiRequest: getSubscriptionCountsCreatorProduct } =
        useSwitcherClient(
            (client) => client.creatorProducts_GetSubscriberCount
        );

    const prepareProductsAndPricing = async (_products: CreatorProduct[]) => {
        const prodSubs = [];
        const productsArr: ExtendedCreatorProduct[] = [];

        //extract prices from products array
        for (let p of _products) {
            const preparePrices = (_prices: CreatorProductPrice[]) => {
                const getMostRecentPrice = (
                    recurringInterval: "year" | "month" | undefined | null
                ) => {
                    return _prices.reduce((memo, currentPrice) => {
                        const priceIsEligible =
                            currentPrice?.RecurringInterval ===
                                recurringInterval && currentPrice?.Active;
                        if (!memo) {
                            // there is no most recent price set yet
                            return priceIsEligible ? currentPrice : memo;
                        } else {
                            // there is a most recent price set as the memo
                            return priceIsEligible &&
                                new Date(currentPrice?.CreatedAt) >
                                    new Date(memo?.CreatedAt)
                                ? currentPrice
                                : memo;
                        }
                    }, null);
                };

                const recurringInterval = _prices?.at(0)?.RecurringInterval;
                const mostRecentPrices: CreatorProductPrice[] = [];

                if (!recurringInterval) {
                    const mostRecentOneTimePrice = getMostRecentPrice(null);
                    if (mostRecentOneTimePrice)
                        mostRecentPrices.push(mostRecentOneTimePrice);
                } else {
                    const mostRecentRecurringMonthlyPrice =
                        getMostRecentPrice("month");
                    const mostRecentRecurringAnnualPrice =
                        getMostRecentPrice("year");
                    if (mostRecentRecurringMonthlyPrice)
                        mostRecentPrices.push(mostRecentRecurringMonthlyPrice);
                    if (mostRecentRecurringAnnualPrice)
                        mostRecentPrices.push(mostRecentRecurringAnnualPrice);
                }

                return mostRecentPrices;
            };
            const productPrices = preparePrices(p.Prices);

            if (p.Prices?.some((price) => price.IsRecurring ?? false)) {
                let subs = await getSubscriptionCountsCreatorProduct([
                    stripeAccountId,
                    p.Id
                ]);
                prodSubs[p.Id] = subs;
            }

            if (p.IsActive && p.Prices?.length > 0) {
                productsArr.push({
                    ...p,
                    Prices: productPrices
                } as ExtendedCreatorProduct);
            }
        }

        setProductSubscribers(prodSubs);

        productsArr.sort(function (a, b) {
            return new Date(a.CreatedAt) < new Date(b.CreatedAt) ? 1 : -1;
        });

        const pricesArr = productsArr.flatMap(
            (product) => product?.Prices ?? []
        );

        setPrices(pricesArr);

        setLoading(false);
        return productsArr;
    };

    const { recurringProducts, oneTimeProducts } = useMemo(() => {
        let recurringProducts = [];
        let oneTimeProducts = [];

        preparedProducts?.forEach((p) => {
            if (p.Prices.some((pr) => pr.IsRecurring)) {
                recurringProducts.push(p);
            } else {
                oneTimeProducts.push(p);
            }
        });

        return {
            recurringProducts,
            oneTimeProducts
        };
    }, [preparedProducts]);

    // When account has been loaded, get products
    useEffect(() => {
        if (!!stripeAccountId && requestImmediately) {
            getProducts([stripeAccountId, true, true, true]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeAccountId]);

    return {
        products: preparedProducts,
        getProducts,
        productSubscribers,
        prices,
        recurringProducts,
        oneTimeProducts,
        loading
    };
};
