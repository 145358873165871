import {
    Broadcast,
    CloudflareVideo,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";

export enum VideoDownloadStatus {
    NotStarted = "notstarted",
    InProgress = "inprogress",
    Processing = "processing",
    Ready = "ready",
    LiveInProgress = "live-inprogress",
    Error = "error"
}

export interface Recording {
    id: string;
    title: string;
    provider: string;
    created: string;
    embedUrl: string;
    shareUrl: string;
    isProtected?: boolean;
    broadcastId?: string;
    readyToStream?: boolean;
    downloadStatus?: string;
    downloadUrl?: string;
    duration?: string;
    broadcast?: Broadcast;
    videos?: CloudflareVideo[];
    players?: VideoPlayer[];
}
