import React from "react";
import styles from "./index.module.scss";

interface BadgeProps {
    className: string;
    tagText: string;
}

export const Badge: React.FC<BadgeProps> = ({ className, tagText }) => {
    return <span className={`badge ${styles[className]}`}>{tagText}</span>;
};
