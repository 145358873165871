import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { ConfirmSubscriptionPageProps, Amounts } from "../types";
import { useNavigate } from "react-router-dom";
import { client } from "api/client";
import { NewAmountTable } from "./NewAmountTable";
import {
    StripeCardElementChangeEvent,
    PaymentRequest,
    PaymentRequestPaymentMethodEvent,
    PaymentIntentResult
} from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { setLoading } from "store/loading/slice";
import { useDebounce } from "hooks/useDebounce";
import { Stepper } from "./Stepper";
import mean from "lodash/mean";
import rollbar from "helpers/rollbar";
import styles from "./ConfirmSubscriptionPage.module.scss";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { useStripeHandlers } from "hooks/useStripeHandlers";
import { calcDiscount } from "utils/js-utils";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useTranslation } from "react-i18next";
import {
    getDefaultUserOrg,
    getUserInfo,
    getUserOrgs,
    refreshToken
} from "store/user/thunks";
import { AppDispatch } from "store/store";
import {
    CreateSubscriptionResponse,
    UpdateSubscriptionResponse,
    SilverSunnStripeCreditCard,
    CouponResponse
} from "@switcherstudio/switcher-api-client";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import { StripeStatus } from "views/page-content/platforms/types";
import { trackConversion, trackEvent } from "helpers/analyticsHelpers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { RadioPaymentForm } from "components/forms/radio-payment-form";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { PlanSummary } from "./PlanSummary";
import { PrimarySecondaryButtonsGroup } from "components/buttons/PrimarySecondaryButtonsGroup";

export const NewConfirmSubscriptionPage: React.FC<
    ConfirmSubscriptionPageProps
> = ({ plan, pricing }: ConfirmSubscriptionPageProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { user } = useSelector((s: RootState) => s);
    const { userInfo } = user;
    const [lastAttemptedInvoice, setLastAttemptedInvoice] = useState<{
        id: string;
        status: string;
        secret: string;
    }>();
    const isMounted = useIsMountedRef();
    const navigate = useNavigate();

    const { stripe, elements } = useStripeHandlers();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);
    const [walletName, setWalletName] = useState<string>("");
    const [showPaymentRequestBtn, setShowPaymentRequestBtn] =
        useState<boolean>(false);
    const [amounts, setAmounts] = useState<Amounts>({
        total: plan.Amount,
        due: plan.Amount,
        proration: 0,
        discount: 0
    });
    const [quantity, setQuantity] = useState(
        plan.Product.Name === "Plus" ? 3 : 1
    );
    const [workspaceName, setWorkspaceName] = useState<string>("");
    const [workspaceNameError, setWorkspaceNameError] = useState<string>("");
    const [quantityError, setQuantityError] = useState<string>("");
    const debouncedQuantity = useDebounce(quantity, 500);
    const [isNewSubscription, setIsNewSubscription] = useState<boolean>(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean>(false);
    const [isPaymentMethodRequired, setIsPaymentMethodRequired] =
        useState<boolean>(true);
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
        SilverSunnStripeCreditCard[]
    >([]);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
        useState<string>();
    const [hidePaymentForm, setHidePaymentForm] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [paymentError, setPaymentError] = useState<string>("");
    const [currentSubscriptionId, setCurrentSubscriptionId] =
        useState<string>("");
    const { claimedCoupon, removeClaimedCoupon, getClaimedCoupon } =
        useGetLastClaimedCoupon();
    const startWithTrial = useMemo(() => {
        return (
            claimedCoupon?.ResellerInventoryItem?.ResellerInventory?.TrialDays >
            0
        );
    }, [claimedCoupon]);
    const getProrationAmounts = useCallback(
        async ({
            quantity,
            subId,
            planCode,
            claimedCoupon
        }: {
            quantity: number;
            subId: string;
            planCode?: string;
            claimedCoupon?: CouponResponse;
        }) => {
            try {
                //do not call api for invalid input
                if (plan.Product.Name === "Plus" && quantity < 3) {
                    return;
                }

                const proration = await client.stripe_GetProration(
                    planCode || plan.Id,
                    quantity,
                    subId,
                    claimedCoupon?.ResellerInventoryItem.Id
                );

                if (isMounted.current) {
                    const payload = {
                        total: proration.TotalAmount || 0,
                        due: proration.AmountDue || 0,
                        proration: proration.ProrationAmount || 0,
                        discount: calcDiscount(
                            proration.TotalAmount || 0,
                            proration.AmountDue || 0,
                            Math.abs(proration.ProrationAmount || 0)
                        )
                    };

                    // If the coupon starts with trial days, display the "Total due today" as $0
                    if (startWithTrial) {
                        payload.due = 0;
                    }

                    setAmounts(payload);
                }
            } catch (e) {
                rollbar.error("Error getting proration amounts", e);
            }
        },
        [isMounted, plan.Id, plan.Product.Name, startWithTrial]
    );

    const cancelChanges = useCallback(() => {
        navigate("/subscription");
    }, [navigate]);

    const changePlan = useCallback(async () => {
        // if there is a claimed coupon for this plan,
        // release it before navigating to /subscribe
        if (claimedCoupon?.ToPlan) {
            await removeClaimedCoupon();
        }

        navigate("/subscription");
    }, [navigate, claimedCoupon, removeClaimedCoupon]);

    useSwitcherClient((client) => client.stripe_GetCustomer, {
        requestImmediately: true,
        onSuccessLoading: true,
        onSuccess: async (c) => {
            if (c.SilverSunnStripeCustomerId) {
                const defaultPaymentMethod = c.StripeCreditCards.find(
                    (cc) => cc.Default && !cc.Expired
                );
                const hasDefaultPaymentMethod = !!defaultPaymentMethod;
                const availablePaymentMethods = c.StripeCreditCards?.filter(
                    (cc) => !cc.Expired
                ).map((cc) => {
                    return {
                        ...cc,
                        optionLabel: `**** ${cc.LastFour} - Exp: ${cc.ExpirationMonth} / ${cc.ExpirationYear}`
                    };
                });
                setShowPaymentRequestBtn(!hasDefaultPaymentMethod);
                setAvailablePaymentMethods(availablePaymentMethods);
                setHasPaymentMethod(availablePaymentMethods?.length > 0);
                setSelectedPaymentMethodId(
                    defaultPaymentMethod?.SilverSunnStripeCreditCardId
                );
                if (hasDefaultPaymentMethod) {
                    setHidePaymentForm(true);
                }

                // Use the first licensed subscription
                const licensedSubscription = c.StripeSubscriptions?.find(
                    (s) => s.UsageType === "licensed"
                );

                if (!licensedSubscription?.SilverSunnStripeSubscriptionId) {
                    setIsNewSubscription(true);
                } else {
                    setCurrentSubscriptionId(
                        licensedSubscription.SilverSunnStripeSubscriptionId
                    );
                }

                // If this is an incomplete/past_due sub, use the last attempted
                // invoice, and set the displayed amounts
                if (
                    licensedSubscription?.Status === StripeStatus.Incomplete ||
                    licensedSubscription?.Status === StripeStatus.PastDue
                ) {
                    const { LatestInvoiceId } =
                        await client.userSubscriptions_GetSubscription(
                            userInfo.UserId,
                            licensedSubscription.SilverSunnStripeSubscriptionId
                        );
                    const invoice = await client.stripeInvoices_GetInvoice(
                        LatestInvoiceId,
                        userInfo.UserId
                    );

                    setLastAttemptedInvoice({
                        id: invoice.id,
                        status: invoice.payment_intent_status,
                        secret: invoice.payment_intent_secret
                    });

                    setAmounts({
                        total: plan.Amount,
                        due: invoice.amount_due,
                        proration: invoice.starting_balance,
                        discount: invoice.total_discount
                    });
                } else {
                    await getClaimedCoupon();
                }
            }
        }
    });

    useEffect(() => {
        if (claimedCoupon) {
            setIsPaymentMethodRequired(
                claimedCoupon.ResellerInventoryItem.ResellerInventory
                    .IsPaymentMethodRequired
            );
        } else {
            setIsPaymentMethodRequired(true);
        }
    }, [claimedCoupon, t]);

    const updateAmountsByQuantity = useCallback(
        async (newQuantity: number) => {
            if (claimedCoupon || claimedCoupon === null) {
                await getProrationAmounts({
                    quantity: newQuantity,
                    subId: currentSubscriptionId,
                    claimedCoupon
                });
            }
        },
        [claimedCoupon, currentSubscriptionId, getProrationAmounts]
    );

    useEffect(() => {
        updateAmountsByQuantity(debouncedQuantity);
    }, [debouncedQuantity, updateAmountsByQuantity]);

    const incrementQuantity = useCallback(() => {
        if (quantityError) {
            setQuantityError("");
        }
        if (quantity <= 999) {
            setQuantity((prev) => (prev += 1));
        }
    }, [quantity, quantityError]);

    const decrementQuantity = useCallback(() => {
        if (quantityError) {
            setQuantityError("");
        }
        if (quantity >= 3) {
            setQuantity((prev) => (prev -= 1));
        }
    }, [quantity, quantityError]);

    const onQuantityChange = useCallback(
        (e) => {
            const value = e.target.value;
            if (quantityError) {
                setQuantityError("");
            }
            if (!isNaN(value)) {
                if (value <= 999) {
                    setQuantity(Number(value));
                }
            }
        },
        [quantityError]
    );

    const onPaymentChange = (event: StripeCardElementChangeEvent) => {
        if (event.error) {
            setPaymentError(event.error.message);
        } else {
            setPaymentError("");
        }
    };

    const onWorkspaceChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setWorkspaceName(e.target.value);
            if (workspaceNameError) {
                setWorkspaceNameError("");
            }
        },
        [workspaceNameError]
    );

    const retryInvoice = useCallback(async () => {
        if (lastAttemptedInvoice?.status) {
            // if a lastAttemptedInvoice is stored, get the latest
            // payment intent status after attaching the payment method.
            const result = await client.stripeInvoices_GetInvoice(
                lastAttemptedInvoice.id,
                userInfo.UserId
            );

            return result.payment_intent_status;
        }

        return;
    }, [lastAttemptedInvoice, userInfo.UserId]);

    const getPaymentMethodId = useCallback(async () => {
        if (selectedPaymentMethodId !== "new-payment-method") {
            // use current default payment method for confirmation
            return selectedPaymentMethodId;
        } else {
            const card = elements.getElement(CardElement);

            if (!card) {
                return;
            }

            // create new payment method
            const { error: pmError, paymentMethod } =
                await stripe.createPaymentMethod({
                    type: "card", // TODO: support other types? Apple Pay, etc
                    card: card
                });

            if (pmError) {
                throw pmError;
            }

            // attach payment method to user
            await client.userPaymentMethods_AttachPaymentMethod(
                userInfo.UserId,
                paymentMethod.id
            );

            trackEvent("Added Payment Info", null, {
                integrations: { Intercom: false, HelpScout: false }
            });

            return paymentMethod.id;
        }
    }, [elements, stripe, userInfo.UserId, selectedPaymentMethodId]);

    const refreshPaymentRequest = useCallback(() => {
        const pr = stripe.paymentRequest({
            country: "US",
            currency: "usd",
            total: {
                label: "Total",
                amount: amounts.due
            },
            requestPayerName: true,
            requestPayerEmail: true,
            disableWallets: ["browserCard"]
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
            if (result) {
                // Payment Request Button click handler
                setPaymentRequest(pr);

                if (result.googlePay) {
                    setWalletName("Google Pay");
                }

                if (result.applePay) {
                    setWalletName("Apple Pay");
                }
            }
        });
    }, [stripe, amounts]);

    /**
     * Creates a PaymentRequest object. If the user has a wallet available,
     * we will set the PaymentRequest, and make a PaymentRequestButton visible.
     */
    useEffect(() => {
        if (stripe) {
            if (!paymentRequest) {
                refreshPaymentRequest();
            } else {
                paymentRequest.update({
                    total: {
                        label: "Total",
                        amount: amounts.due
                    }
                });
            }
        }
    }, [stripe, amounts, paymentRequest, refreshPaymentRequest]);

    const makeSubscription = useCallback(
        async (retryStatus?: string) => {
            let subscriptionResponse:
                | CreateSubscriptionResponse
                | UpdateSubscriptionResponse = null;
            let orgId = userInfo.OrganizationId;
            let newSubId: string;

            if (!retryStatus) {
                if (plan.Product.Name === "Plus" && !orgId) {
                    // create new org
                    const newOrg = await client.organizations_PostOrganization({
                        Name: workspaceName || `${userInfo.Email}'s team`
                    });
                    orgId = newOrg.Id;

                    // make user org admin
                    await client.organizationUsers_PostOrganizationAdmin(
                        orgId,
                        {
                            Email: userInfo.Email
                        },
                        false
                    );
                }

                const options = {
                    OrganizationId: orgId,
                    ResellerInventoryItemId:
                        claimedCoupon?.ResellerInventoryItem.Id,
                    Plan: plan.Id,
                    Quantity: debouncedQuantity
                };

                try {
                    if (isNewSubscription) {
                        // create new subscription - this is called if the user
                        // has NO subscription, which is possible if their previous
                        // subscription has been canceled and the cancellation date
                        // has passed
                        const res: CreateSubscriptionResponse =
                            await client.userSubscriptions_CreateSubscription(
                                userInfo.UserId,
                                {
                                    ...options,
                                    TrialDays: 0,
                                    Source: "SilverSunnDashboard"
                                }
                            );
                        subscriptionResponse = res;
                        newSubId = res.SubscriptionId;
                    } else {
                        // update user's current subscription
                        subscriptionResponse =
                            await client.userSubscriptions_UpdateSubscription(
                                userInfo.UserId,
                                currentSubscriptionId,
                                options
                            );
                    }
                    const plans = await client.metrics_GetPlansLtv();
                    let planInfo = plans.filter((p) => p.PlanId === plan.Id)[0];

                    if (!planInfo) {
                        planInfo = {
                            Ltv: Number(
                                mean(
                                    plans
                                        .filter((p) => (p.Ltv || 0) > 0)
                                        .map((p) => p.Ltv)
                                ).toFixed(2)
                            ),
                            PlanId: plan.Id
                        };
                    }

                    // legacy "Subscribed" event - deprecated
                    trackEvent(
                        "Subscribed",
                        {
                            currency: "USD",
                            predicted_ltv: planInfo.Ltv,
                            value: planInfo.Ltv,
                            planId: planInfo.PlanId,
                            category: "Billing",
                            label: planInfo.PlanId
                        },
                        {
                            integrations: {
                                Intercom: false,
                                HelpScout: false
                            }
                        }
                    );

                    trackConversion(
                        startWithTrial,
                        "SilverSunnDashboard",
                        plan,
                        userInfo.UserId
                    );
                } catch (e) {
                    if (e.status === 402) {
                        dispatch(
                            addNotification({
                                type: NotificationType.Danger,
                                message: t("subscription:update-error")
                            })
                        );
                    }
                }
            }
            return { newSubId, subscriptionResponse, orgId };
        },
        [
            currentSubscriptionId,
            debouncedQuantity,
            isNewSubscription,
            plan,
            userInfo,
            claimedCoupon,
            workspaceName,
            startWithTrial,
            dispatch,
            t
        ]
    );

    const confirmPayment = useCallback(
        async (
            subscriptionResponse,
            retryStatus,
            paymentMethodId,
            prEvent: PaymentRequestPaymentMethodEvent
        ) => {
            if (
                subscriptionResponse?.SubscriptionStatus !== "active" &&
                subscriptionResponse?.PaymentIntentStatus ===
                    "requires_payment_method"
            ) {
                setLastAttemptedInvoice({
                    id: subscriptionResponse.LatestInvoiceId,
                    status: subscriptionResponse.PaymentIntentStatus,
                    secret: subscriptionResponse.PaymentIntentSecret
                });

                throw new Error("Your card was declined.");
            }

            if (
                (subscriptionResponse?.SubscriptionStatus !== "active" &&
                    subscriptionResponse?.PaymentIntentStatus ===
                        "requires_action") ||
                retryStatus === "requires_payment_method" ||
                retryStatus === "requires_action"
            ) {
                // If using a PaymentRequest, we must set handleActions to false, so that we can
                // close the PaymentRequest overlay and allow the user to interact with potential
                // modals for SCA/3DS, etc.
                const { error: confirmPaymentError, paymentIntent: pi } =
                    await stripe.confirmCardPayment(
                        subscriptionResponse?.PaymentIntentSecret ||
                            lastAttemptedInvoice?.secret,
                        {
                            payment_method: paymentMethodId,
                            setup_future_usage: "off_session"
                        },
                        {
                            handleActions: prEvent ? false : true
                        }
                    );

                let reconfirm: PaymentIntentResult;

                if (prEvent && pi?.status === "requires_action") {
                    prEvent.complete("success");
                    reconfirm = await stripe.confirmCardPayment(
                        pi.client_secret
                    );
                }

                if (confirmPaymentError || reconfirm?.error) {
                    setLastAttemptedInvoice({
                        id:
                            subscriptionResponse?.LatestInvoiceId ||
                            lastAttemptedInvoice.id,
                        status:
                            subscriptionResponse?.PaymentIntentStatus ||
                            lastAttemptedInvoice.status,
                        secret:
                            subscriptionResponse?.PaymentIntentSecret ||
                            lastAttemptedInvoice.secret
                    });

                    throw confirmPaymentError || reconfirm?.error;
                }
            }
        },
        [lastAttemptedInvoice, stripe]
    );

    const refreshUserInfo = useCallback(
        async (orgId: string) => {
            try {
                if (plan.Product.Name === "Plus") {
                    const userOrgs = await dispatch(getUserOrgs()).unwrap();
                    const orgUser = userOrgs.orgs.find(
                        (o) => o.OrganizationId === orgId
                    );
                    await client.userOrganizations_DeleteDefault(
                        userInfo.UserId
                    );
                    await client.userOrganizations_PostDefault(
                        userInfo.UserId,
                        orgUser.Id
                    );
                }

                await dispatch(refreshToken());
                await dispatch(getUserInfo());
                await dispatch(getUserOrgs());
                await dispatch(getDefaultUserOrg());
            } catch (e) {
                rollbar.error("Error refreshing user info", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:user-info-load-error")
                    })
                );
            }
        },
        [dispatch, plan.Product.Name, userInfo.UserId, t]
    );

    const goToSuccessPage = useCallback(() => {
        if (hasPaymentMethod) {
            navigate(
                `/subscription/change-success${
                    hidePaymentForm ? "" : "?pm=new"
                }`
            );
        } else {
            navigate("/subscription/success");
        }
    }, [hasPaymentMethod, hidePaymentForm, navigate]);

    /** Method for collecting payment and completing the order.
     * This method is shared by the submit button and the
     * `paymentmethod` listener on the PaymentRequestButton.
     * On the submit button, the `PaymentRequestPaymentMethodEvent`
     * will be undefined.
     */
    const collectPayment = useCallback(
        async (prEvent?: PaymentRequestPaymentMethodEvent) => {
            // workspace name is required for new Plus plans & validate team member quantity input
            if (!lastAttemptedInvoice && plan.Product.Name === "Plus") {
                if (user.defaultOrg === null && !workspaceName) {
                    setWorkspaceNameError(t("errors:workspace-name-error"));
                    prEvent?.complete("fail");
                    return;
                }
                if (quantity < 3) {
                    setQuantityError(t("errors:team-qty-error"));
                    prEvent?.complete("fail");
                    return;
                }
            }

            dispatch(setLoading(1));
            setIsSubmitting(true);
            setPaymentError("");

            let paymentMethodId: string;

            try {
                if (isPaymentMethodRequired) {
                    /**
                     *  This function only has a prEvent when it is used as the callback
                     *  for the Stripe PaymentRequestButton (Apple Pay / Google Pay)
                     *  If the event is null, it means that we used an existing payment method
                     *  or a new payment method (traditional card)
                     */
                    if (prEvent?.paymentMethod.id) {
                        paymentMethodId = prEvent?.paymentMethod.id;
                        // attach Apple Pay payment method to existing customer
                        await client.userPaymentMethods_AttachPaymentMethod(
                            userInfo.UserId,
                            paymentMethodId
                        );

                        trackEvent(
                            `Added Payment Info with ${prEvent.walletName}`,
                            null,
                            {
                                integrations: {
                                    Intercom: false,
                                    HelpScout: false
                                }
                            }
                        );
                    } else {
                        paymentMethodId = await getPaymentMethodId();
                    }

                    if (!paymentMethodId) {
                        throw new Error(t("errors:no-payment-method"));
                    }

                    // Sets the selected payment method as primary
                    await client.userPaymentMethods_SetPrimaryPaymentMethod(
                        userInfo.UserId,
                        paymentMethodId
                    );

                    const retryStatus = await retryInvoice();

                    const subscription = await makeSubscription(retryStatus);

                    if (!subscription) {
                        throw new Error(t("errors:subscription-failure"));
                    }

                    // pull properties from the subscription object
                    const { newSubId, subscriptionResponse, orgId } =
                        subscription;

                    await confirmPayment(
                        subscriptionResponse,
                        retryStatus,
                        paymentMethodId,
                        prEvent
                    );

                    await client.userSubscriptions_ReloadSubscription(
                        userInfo.UserId,
                        currentSubscriptionId || newSubId
                    );

                    await refreshUserInfo(orgId);
                } else {
                    // If payment method is not required, just create or update the subscription
                    const subscription = await makeSubscription();
                    if (!subscription) {
                        throw new Error(t("errors:subscription-failure"));
                    }

                    const { newSubId, orgId } = subscription;
                    await client.userSubscriptions_ReloadSubscription(
                        userInfo.UserId,
                        currentSubscriptionId || newSubId
                    );
                    await refreshUserInfo(orgId);
                }

                prEvent?.complete("success");
                goToSuccessPage();
            } catch (e) {
                prEvent?.complete("fail");

                // If a new payment method is used, try to reset the user's default
                // payment method and delete the new failed one.
                if (paymentMethodId && (!hidePaymentForm || prEvent)) {
                    try {
                        if (availablePaymentMethods.length) {
                            const originalDefault =
                                availablePaymentMethods.find(
                                    (c) => c.Default && !c.Expired
                                );
                            if (originalDefault) {
                                await client.userPaymentMethods_SetPrimaryPaymentMethod(
                                    userInfo.UserId,
                                    originalDefault.SilverSunnStripeCreditCardId
                                );
                            }
                        }

                        await client.userPaymentMethods_DeletePaymentMethod(
                            userInfo.UserId,
                            paymentMethodId
                        );
                    } catch {
                        /** eat DeletePaymentMethod request errors */
                    }
                }

                refreshPaymentRequest();

                if (!!e?.error?.decline_code) {
                    rollbar.warning(
                        "Payment collection error with decline code",
                        e
                    );
                } else {
                    rollbar.error(
                        "Payment collection error without decline code",
                        e
                    );
                }

                setPaymentError(e.message || e.error.decline_code);
            } finally {
                dispatch(setLoading(-1));
                setIsSubmitting(false);
            }
        },
        [
            availablePaymentMethods,
            currentSubscriptionId,
            isPaymentMethodRequired,
            lastAttemptedInvoice,
            confirmPayment,
            dispatch,
            getPaymentMethodId,
            goToSuccessPage,
            hidePaymentForm,
            makeSubscription,
            plan.Product.Name,
            refreshUserInfo,
            retryInvoice,
            workspaceName,
            t,
            quantity,
            user.defaultOrg,
            userInfo.UserId,
            refreshPaymentRequest
        ]
    );

    const onSubmit = useCallback(
        async (event?: React.FormEvent<HTMLFormElement>) => {
            event?.preventDefault();
            // do nothing in case stripe/elements have not loaded yet
            if (!stripe || !elements) {
                return;
            }

            collectPayment();
        },
        [elements, stripe, collectPayment]
    );

    /**
     * Adds/updates a `paymentmethod` listener for the payment request button.
     */
    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.on("paymentmethod", collectPayment);
        }

        return () => {
            paymentRequest?.off("paymentmethod");
        };
    }, [collectPayment, paymentRequest]);

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className={styles["almost-official-container"]}>
                        {lastAttemptedInvoice ? (
                            <p className="lead mr-5">
                                {t("subscription:could-not-collect-1")}{" "}
                                <strong>
                                    {t("subscription:could-not-collect-2", {
                                        title: plan.Product.Name,
                                        pricing: t(
                                            `subscription:${pricing}`
                                        ).toLowerCase()
                                    })}
                                </strong>{" "}
                                {t("subscription:could-not-collect-3")}
                            </p>
                        ) : (
                            <p className="lead">
                                {t("subscription:almost-official-1", {
                                    pricing: t(
                                        `subscription:${pricing}`
                                    ).toLowerCase()
                                })}{" "}
                                <strong>
                                    {t("subscription:almost-official-2", {
                                        title: plan.Product.Name
                                    })}
                                </strong>{" "}
                                {t("subscription:almost-official-3")}
                            </p>
                        )}
                        <p>
                            <small>{t("subscription:fine-text")}</small>
                        </p>
                    </div>
                    <div
                        className={
                            styles["payment-method-and-summary-container"]
                        }
                    >
                        <div className={styles["payment-summary-container"]}>
                            <h5>{t("subscription:payment-summary-header")}</h5>
                            <PlanSummary
                                plan={plan}
                                onChangePlan={changePlan}
                            />
                            <NewAmountTable
                                amounts={amounts}
                                pricing={pricing}
                            />
                            {startWithTrial && (
                                <div className="alert alert-info">
                                    {t("subscription:start-with-trial-alert", {
                                        trialDays:
                                            claimedCoupon.ResellerInventoryItem
                                                .ResellerInventory.TrialDays
                                    })}
                                </div>
                            )}

                            {/** Putting the subscribe/cancel button here if no payment is required since the payment method div will be empty */}
                            {!isPaymentMethodRequired && (
                                <PrimarySecondaryButtonsGroup
                                    primaryButtonText={t(
                                        "subscription:subscribe"
                                    )}
                                    primaryButtonType="button"
                                    onPrimaryButtonClick={onSubmit}
                                    secondaryButtonText={t(
                                        "subscription:cancel-changes"
                                    )}
                                    onSecondaryButtonClick={cancelChanges}
                                />
                            )}
                        </div>
                        <div className={styles["payment-method-container"]}>
                            {plan.Product.Name === "Plus" && (
                                <div className="mb-4">
                                    <h5 className="mb-3">
                                        {t("subscription:team-details")}
                                    </h5>
                                    {!lastAttemptedInvoice && (
                                        <>
                                            {user.defaultOrg === null && (
                                                <TextInput
                                                    id="workspaceName"
                                                    type="text"
                                                    label={t(
                                                        "subscription:new-workspace-name"
                                                    )}
                                                    placeholder={t(
                                                        "subscription:new-workspace-name-placeholder"
                                                    )}
                                                    value={workspaceName}
                                                    onChange={onWorkspaceChange}
                                                />
                                            )}
                                            {workspaceNameError && (
                                                <div
                                                    className="alert alert-danger"
                                                    role="alert"
                                                >
                                                    {workspaceNameError}
                                                </div>
                                            )}
                                            <Stepper
                                                label={t(
                                                    "subscription:team-members"
                                                )}
                                                inc={incrementQuantity}
                                                dec={decrementQuantity}
                                                onChange={onQuantityChange}
                                                value={quantity}
                                                min={3}
                                                max={999}
                                            />
                                            {quantityError && (
                                                <div
                                                    className="alert alert-danger mt-3 mb-0"
                                                    role="alert"
                                                >
                                                    {quantityError}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            <>
                                {isPaymentMethodRequired && (
                                    <h5>{t("subscription:payment-method")}</h5>
                                )}
                                {isPaymentMethodRequired &&
                                paymentRequest &&
                                showPaymentRequestBtn ? (
                                    <>
                                        <PaymentRequestButtonElement
                                            options={{ paymentRequest }}
                                        />
                                        {paymentError && (
                                            <div
                                                className="alert alert-danger"
                                                role="alert"
                                            >
                                                {paymentError}
                                            </div>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-block btn-link mt-3"
                                            onClick={() =>
                                                setShowPaymentRequestBtn(
                                                    (p) => !p
                                                )
                                            }
                                        >
                                            {t(
                                                "subscription:or-enter-manually"
                                            )}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {isPaymentMethodRequired && (
                                            <>
                                                <RadioPaymentForm
                                                    buttonText={
                                                        lastAttemptedInvoice
                                                            ? t(
                                                                  "subscription:retry-payment"
                                                              )
                                                            : t(
                                                                  "subscription:subscribe"
                                                              )
                                                    }
                                                    onSubmit={onSubmit}
                                                    onChange={onPaymentChange}
                                                    error={paymentError}
                                                    busy={isSubmitting}
                                                    availablePaymentMethods={
                                                        availablePaymentMethods
                                                    }
                                                    selectedPaymentMethodId={
                                                        selectedPaymentMethodId
                                                    }
                                                    setSelectedPaymentMethodId={
                                                        setSelectedPaymentMethodId
                                                    }
                                                />

                                                {paymentRequest && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-block btn-link mt-3"
                                                        onClick={() =>
                                                            setShowPaymentRequestBtn(
                                                                (p) => !p
                                                            )
                                                        }
                                                    >
                                                        {t(
                                                            "subscription:or-use-wallet",
                                                            { walletName }
                                                        )}
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
