import { exists } from "./booleans";

export const isEmptyOrUndefined = (obj: object) => {
    if (!obj) return true;

    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
};

export const hasValues = (obj: object): boolean =>
    Object.values(obj).some((p) => exists(p));

export const deepEqual = <T extends object>(obj1: T, obj2: T): boolean => {
    if (obj1 === obj2) return true;

    if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
    ) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};

export type WithKey<K extends string, T> = { [key in K]: T } & {
    [key: string]: any;
};

export type WithOptionalKey<K extends string, T> = { [key in K]?: T } & {
    [key: string]: any;
};

export const updateKey = <O extends string, N extends string>(
    object: WithOptionalKey<O, any>,
    oldKey: O,
    newKey: N
): WithKey<N, any> => {
    object[newKey] = object[oldKey] as any;
    delete object[oldKey];

    return object as unknown as WithKey<N, any>;
};
