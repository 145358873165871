import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import {
    PlayerDetailsResponse,
    PlayerResponse,
    VideoPlayer,
    VideoPlayerAspectRatio,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { VideoPlayerThumbnail } from "components/thumbnails/VideoPlayerThumbnail";
import { Button } from "components/buttons/Button";
import { ContextMenu, ContextMenuItem } from "components/inputs/context-menu";
import { ContextMenuWrapper } from "components/inputs/context-menu-button";
import { useIsMobile } from "hooks/useIsMobile";
import { useClipboard } from "hooks/useClipboard";
import PencilIcon from "assets/icons/pencil-simple.svg?react";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import ShareIcon from "assets/icons/share.svg?react";
import { useNavigate } from "react-router-dom";
import { BaseDetails } from "../BaseDetails";
import { displayAspectRatio } from "helpers/display";
import { ComponentMap } from "components/utility/ComponentMap";
import { PlayerBadges } from "components/badges/PlayerBadges";
import { DetailRow } from "../BaseDetails/DetailRow";
import { Toggle } from "components/inputs/toggle/Toggle";
import { useSettableMemo } from "hooks/useSettableMemo";
import { useDebounce } from "hooks/useDebounce";
import { mapPlayerDetailsResponseResponseToVideoPlayer } from "helpers/mappers/videoPlayers";

interface PlayerDetailsProps {
    item: VideoPlayerResponse | PlayerResponse;
    showEdit?: boolean;
    showDelete?: boolean;
    showShare?: boolean;
    showPrivacyToggle?: boolean;
    isDraggable?: boolean;
    handleDelete?: (videoPlayerId: string) => void;
    onPrivacyToggle?: (player: PlayerDetailsResponse, value: boolean) => void;
}

// Is VideoPlayerResponse
const isVPR = (item: any): item is VideoPlayerResponse => {
    return (item as VideoPlayerResponse).VideoPlayer !== undefined;
};

export const PlayerDetails = ({
    item,
    showEdit = false,
    showDelete = false,
    showShare = false,
    showPrivacyToggle = false,
    isDraggable = false,
    handleDelete,
    onPrivacyToggle
}: PlayerDetailsProps) => {
    const { t } = useTranslation();
    const { copy } = useClipboard();
    const mobileCheck = useIsMobile();
    const navigate = useNavigate();

    const title = useMemo(() => {
        return isVPR(item)
            ? item.VideoPlayer.Name
            : item.Details.Name ?? t("players-select-modal:untitled");
    }, [item, t]);

    const videosLength = useMemo(() => {
        const count = isVPR(item)
            ? item?.VideoPlayer?.VideoPlayerPlaylists?.[0]
                  ?.VideoPlayerPlaylistBroadcasts?.length
            : item?.Playlists?.[0]?.Items?.length ?? 0;

        return count === 1
            ? t("players:video")
            : t("players:video_plural", { count });
    }, [item, t]);

    const viewCount = useMemo((): string | undefined => {
        let totalViews;

        if (isVPR(item)) {
            if (
                !item?.VideoMetricsSummary ||
                item?.VideoMetricsSummary?.TotalViews == 0
            ) {
                return undefined;
            }
            totalViews = item?.VideoMetricsSummary?.TotalViews;
        } else {
            if (
                !item?.MetricsSummary?.TotalViews ||
                item?.MetricsSummary?.TotalViews == 0
            ) {
                return undefined;
            }
            totalViews = item?.MetricsSummary?.TotalViews;
        }

        return totalViews > 1
            ? `${totalViews} ${t("players:lifetime-views")}`
            : `${totalViews} ${t("players:lifetime-view")}`;
    }, [item, t]);

    const infoItems = useMemo(() => {
        const aspectRatio = isVPR(item)
            ? item?.VideoPlayer?.AspectRatio
            : (item?.Details?.AspectRatio as unknown as VideoPlayerAspectRatio);
        return viewCount
            ? [displayAspectRatio(aspectRatio), videosLength, viewCount]
            : [displayAspectRatio(aspectRatio), videosLength];
    }, [item, videosLength, viewCount]);

    const shareOptions = useMemo<ContextMenuItem[]>(() => {
        if (!showShare) return [];

        const embedCode = isVPR(item) ? item?.EmbedCode : item?.Meta?.EmbedCode;
        const micrositeUrl = isVPR(item)
            ? item?.MicrositeUrl
            : item?.Meta?.MicrositeUrl;

        return [
            {
                key: "copy-code",
                text: t("buttons:copy-embed-code"),
                onClick: () => copy(embedCode)
            },
            {
                key: "copy-link",
                text: t("buttons:copy-share-link"),
                onClick: () => copy(micrositeUrl)
            }
        ];
    }, [item, copy, t, showShare]);

    const handleEdit = useCallback(() => {
        navigate(
            `/catalog/collections/${
                isVPR(item) ? item.VideoPlayer.Id : item.Details.Id
            }`
        );
    }, [item, navigate]);

    const handleDeleteLocal = useCallback(() => {
        handleDelete &&
            handleDelete(isVPR(item) ? item.VideoPlayer.Id : item.Details.Id);
    }, [handleDelete, item]);

    const mobileButtonsMenuItems = useMemo(() => {
        let options = [...shareOptions];

        if (showEdit) {
            options = [
                {
                    key: "Edit",
                    text: t("buttons:edit"),
                    onClick: handleEdit
                },
                ...options
            ];
        }

        if (showDelete) {
            options = [
                ...options,
                {
                    key: "Delete",
                    text: t("buttons:delete"),
                    onClick: handleDeleteLocal
                }
            ];
        }

        return options;
    }, [t, handleEdit, handleDeleteLocal, shareOptions, showEdit, showDelete]);

    const [privacyToggleOn, setPrivacyToggleOn] = useSettableMemo(
        () =>
            isVPR(item)
                ? item.VideoPlayer.ShowInCatalog
                : item.Details.ShowInCatalog,
        [item]
    );

    const handleUpdatePrivacyToggle = useCallback(
        (value: boolean) => {
            if (
                isVPR(item)
                    ? item.VideoPlayer.ShowInCatalog
                    : item.Details.ShowInCatalog !== value
            ) {
                // only the catalog (which uses the PlayerResponse) uses the onPrivacyToggle function
                onPrivacyToggle?.(isVPR(item) ? null : item.Details, value);
            }
        },
        [item, onPrivacyToggle]
    );

    useDebounce(privacyToggleOn, 500, {
        onUpdate: handleUpdatePrivacyToggle
    });

    const player = useMemo<VideoPlayer>(
        () =>
            isVPR(item)
                ? item.VideoPlayer
                : mapPlayerDetailsResponseResponseToVideoPlayer(item.Details),
        [item]
    );

    const entitlements = useMemo(() => {
        if (isVPR(item)) {
            return item?.VideoPlayerEntitlements?.ProductEntitlements;
        } else {
            return item?.Entitlements?.ProductEntitlements;
        }
    }, [item]);

    const showBadges = useMemo<boolean>(
        () =>
            entitlements?.length > 0 ||
            player.IsEmailGatingEnabled ||
            player.IsPasswordGatingEnabled,
        [entitlements, player]
    );

    return (
        <>
            {!!item && (
                <div className={styles["player-details"]}>
                    <BaseDetails
                        isDraggable={isDraggable}
                        thumbnail={<VideoPlayerThumbnail {...item.Thumbnail} />}
                        title={title}
                        hasToggleRow={true}
                        detailRows={
                            <>
                                <DetailRow variant="info-row">
                                    <ComponentMap
                                        items={infoItems}
                                        element={(i) => <span>{i}</span>}
                                        separator={<span>{"•"}</span>}
                                    />
                                </DetailRow>
                                {showBadges && (
                                    <DetailRow variant="badge-row">
                                        <PlayerBadges
                                            player={player}
                                            entitlement={entitlements?.[0]}
                                        />
                                    </DetailRow>
                                )}
                                {showPrivacyToggle && (
                                    <DetailRow variant="toggle-row">
                                        <Toggle
                                            on={privacyToggleOn}
                                            onToggle={() =>
                                                setPrivacyToggleOn(
                                                    !privacyToggleOn
                                                )
                                            }
                                            label={t(
                                                "collections-page:show-in-catalog"
                                            )}
                                            className={
                                                styles["visibility-toggle"]
                                            }
                                            reverseLayout
                                        />
                                    </DetailRow>
                                )}
                            </>
                        }
                        actionItems={
                            <>
                                {(showEdit || showDelete || showShare) && (
                                    <>
                                        {!mobileCheck.isMobile ? (
                                            <>
                                                {showEdit && (
                                                    <Button
                                                        type="icon"
                                                        onClick={handleEdit}
                                                        title={t(
                                                            "buttons:edit"
                                                        )}
                                                    >
                                                        <PencilIcon
                                                            className={
                                                                styles[
                                                                    "btn-edit"
                                                                ]
                                                            }
                                                        />
                                                    </Button>
                                                )}
                                                {showShare && (
                                                    <ContextMenuWrapper
                                                        items={shareOptions}
                                                    >
                                                        <Button
                                                            type="icon"
                                                            title={t(
                                                                "buttons:share"
                                                            )}
                                                        >
                                                            <ShareIcon />
                                                        </Button>
                                                    </ContextMenuWrapper>
                                                )}
                                                {showDelete && (
                                                    <Button
                                                        type="icon"
                                                        onClick={
                                                            handleDeleteLocal
                                                        }
                                                        title={t(
                                                            "buttons:delete"
                                                        )}
                                                    >
                                                        <TrashIcon
                                                            className={
                                                                styles[
                                                                    "trash-icon"
                                                                ]
                                                            }
                                                        />
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <ContextMenu
                                                dropDirection="up"
                                                items={mobileButtonsMenuItems}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        }
                    ></BaseDetails>
                </div>
            )}
        </>
    );
};
