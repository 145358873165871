import GatedContentCards from "components/radio-cards/gated-content-cards";
import { ActiveSubscribersTable } from "components/modal/GatedContentModal/ActiveSubscribersTable";
import { Toggle } from "components/inputs/toggle/Toggle";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

export const CatalogPurchaseOptions = ({
    values,
    priceErrors,
    handlePriceChange,
    createOrUpdate,
    entitlementAndPrices,
    oneTimePassFeatureEnabled,
    recurringApplySubscriptionUpdatesNewOnly,
    handleToggle
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={styles["pass-container"]}>
                <GatedContentCards
                    values={values}
                    errors={priceErrors}
                    type={createOrUpdate}
                    variant="catalog"
                    onPassChange={handlePriceChange}
                    creatorProductId={
                        entitlementAndPrices?.ProductEntitlements?.[0]
                            ?.ProductId
                    }
                    shouldShowOneTimePassOption={oneTimePassFeatureEnabled}
                />
            </div>

            <ActiveSubscribersTable
                creatorProduct={entitlementAndPrices?.ProductEntitlements?.[0]?.Product}
            />

            <Toggle
                label={t(
                    "gated-content:subscription-update:update-to-new-only"
                )}
                on={recurringApplySubscriptionUpdatesNewOnly}
                onToggle={() =>
                    handleToggle(!recurringApplySubscriptionUpdatesNewOnly)
                }
                className={styles["toggle"]}
                reverseLayout={true}
            />
            {!recurringApplySubscriptionUpdatesNewOnly && (
                <div className={styles["recurring-update-subscribers-warning"]}>
                    {t(
                        "gated-content:subscription-update:update-to-new-only-warning"
                    )}
                </div>
            )}
        </>
    );
};
