import {
    GetInvoiceResponse,
    SilverSunnStripeSubscription,
    StripeInvoiceBindingModel,
    StripePrice
} from "@switcherstudio/switcher-api-client";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useStripeHandlers } from "./useStripeHandlers";
import { t } from "i18next";
import { useSwitcherClient } from "./useSwitcherClient";
import { createUrl } from "helpers/url";
import { stringifyBool } from "helpers/booleans";
import { client } from "api/client";
import { trackEvent, trackConversion } from "helpers/analyticsHelpers";
import mean from "lodash/mean";
import { StripeError } from "@stripe/stripe-js";

export interface useCheckoutOptions {
    licensedSubscription: SilverSunnStripeSubscription | string;
    resellerInventoryItemId: string;
    plan: StripePrice | string;
    isNewSubscription: boolean;
    isTrialing: boolean;
    lastAttemptedInvoice?: StripeInvoiceBindingModel | GetInvoiceResponse;
    onSuccess?: (args: { selectedPaymentMethodId: string }) => void;
    onError?: (
        message: string,
        args: { selectedPaymentMethodId: string; stripeError?: StripeError }
    ) => void;
}

export const useCheckout = ({
    licensedSubscription,
    resellerInventoryItemId,
    plan,
    isNewSubscription,
    isTrialing,
    lastAttemptedInvoice,
    onSuccess,
    onError
}: useCheckoutOptions) => {
    const { stripe, elements } = useStripeHandlers();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string>("");
    const currentSubscriptionId = useMemo(
        () =>
            typeof licensedSubscription === "string"
                ? licensedSubscription
                : licensedSubscription?.SilverSunnStripeSubscriptionId,
        [licensedSubscription]
    );

    const planId = useMemo<string>(
        (): string => (typeof plan === "string" ? plan : plan?.Id),
        [plan]
    );

    const returnUrl = useCallback(
        ({
            invoiceId,
            selectedPaymentMethodId
        }: {
            selectedPaymentMethodId: string;
            invoiceId?: string;
        }): string =>
            createUrl(window.location.origin, {
                pathname: "/subscription",
                searchParams: {
                    planId: planId,
                    isTrialing: stringifyBool(isTrialing),
                    isNewSubscription: stringifyBool(isNewSubscription),
                    isNewPaymentMethod: stringifyBool(
                        selectedPaymentMethodId === "new-payment-method"
                    ),
                    currentSubscriptionId,
                    invoiceId: invoiceId,
                    resellerInventoryItemId
                }
            }).toString(),
        [
            currentSubscriptionId,
            isNewSubscription,
            isTrialing,
            planId,
            resellerInventoryItemId
        ]
    );

    const { dispatchApiRequest: createSubscription } = useSwitcherClient(
        (client) => client.userSubscriptions_CreateSubscription,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: updateSubscription } = useSwitcherClient(
        (client) => client.userSubscriptions_UpdateSubscription,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: reloadSubscription } = useSwitcherClient(
        (client) => client.userSubscriptions_ReloadSubscription,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const retryInvoice = useCallback(async (): Promise<
        GetInvoiceResponse | undefined
    > => {
        if (lastAttemptedInvoice) {
            // if a lastAttemptedInvoice is stored, get the latest
            // payment intent status after attaching the payment method.
            const result = await client.stripeInvoices_GetInvoice(
                lastAttemptedInvoice.id,
                userInfo.UserId
            );

            return result;
        }

        return;
    }, [lastAttemptedInvoice, userInfo.UserId]);

    const trackSubscription = useCallback(async (plan: string) => {
        const plans = await client.metrics_GetPlansLtv();
        let planInfo = plans.filter((p) => p.PlanId === plan)[0];

        if (!planInfo) {
            planInfo = {
                Ltv: Number(
                    mean(
                        plans.filter((p) => (p.Ltv || 0) > 0).map((p) => p.Ltv)
                    ).toFixed(2)
                ),
                PlanId: plan
            };
        }

        // legacy "Subscribed" event - deprecated
        trackEvent(
            "Subscribed",
            {
                currency: "USD",
                predicted_ltv: planInfo.Ltv,
                value: planInfo.Ltv,
                planId: planInfo.PlanId,
                category: "Billing",
                label: planInfo.PlanId
            },
            {
                integrations: {
                    Intercom: false,
                    HelpScout: false
                }
            }
        );

        try {
            await trackConversion(
                true,
                "SilverSunnDashboard",
                await client.stripePrices_GetPrice(plan),
                userInfo.UserId
            );
        } catch {}
    }, [userInfo.UserId]);

    const createOrUpdateSubscription = useCallback(
        async ({
            planId
        }: {
            selectedPaymentMethodId: string;
            planId: string;
        }) => {
            const options = {
                OrganizationId: userInfo.OrganizationId,
                ResellerInventoryItemId: resellerInventoryItemId,
                Plan: planId,
                Quantity: 1
            };

            if (isNewSubscription) {
                // create new subscription - this is called if the user
                // has NO subscription, which is possible if their previous
                // subscription has been canceled and the cancellation date
                // has passed

                await createSubscription([
                    userInfo.UserId,
                    {
                        ...options,
                        TrialDays: 0,
                        Source: "SilverSunnDashboard"
                    }
                ]);
            } else {
                // update user's current subscription
                await updateSubscription([
                    userInfo.UserId,
                    currentSubscriptionId,
                    {
                        ...options
                    }
                ]);
            }
            await trackSubscription(planId);
        },
        [
            userInfo,
            resellerInventoryItemId,
            isNewSubscription,
            trackSubscription,
            createSubscription,
            updateSubscription,
            currentSubscriptionId
        ]
    );

    const finalizeInvoiceIfDraft = useCallback(
        async (
            invoice: GetInvoiceResponse
        ): Promise<StripeInvoiceBindingModel> => {
            if (invoice.payment_intent_status === null) {
                return await client.stripeInvoices_FinalizeInvoice(
                    userInfo.UserId,
                    invoice.id
                );
            } else {
                return invoice;
            }
        },
        [userInfo]
    );

    const chargeInvoice = useCallback(
        async ({
            invoice,
            selectedPaymentMethodId
        }: {
            invoice: GetInvoiceResponse | string;
            selectedPaymentMethodId: string;
        }) => {
            let finalizedInvoice =
                typeof invoice === "string"
                    ? await client.stripeInvoices_FinalizeInvoice(
                          userInfo.UserId,
                          invoice
                      )
                    : await finalizeInvoiceIfDraft(invoice);

            const { error, paymentIntent } = await stripe.confirmPayment({
                elements:
                    selectedPaymentMethodId === "new-payment-method"
                        ? elements
                        : undefined,
                clientSecret: finalizedInvoice.payment_intent_secret,
                redirect: "if_required",
                confirmParams: {
                    save_payment_method: true,
                    payment_method:
                        selectedPaymentMethodId !== "new-payment-method"
                            ? selectedPaymentMethodId
                            : undefined,
                    return_url: returnUrl({
                        invoiceId: finalizedInvoice.id,
                        selectedPaymentMethodId
                    })
                }
            });

            if (error || !paymentIntent) {
                throw error;
            }

            const { payment_method } = paymentIntent;

            await client.userPaymentMethods_SetPrimaryPaymentMethod(
                userInfo.UserId,
                typeof payment_method === "string"
                    ? payment_method
                    : payment_method.id
            );

            finalizedInvoice = await client.stripeInvoices_GetInvoice(
                finalizedInvoice.id,
                userInfo.UserId
            );

            if (!finalizedInvoice.paid) {
                throw new Error("Invoice not paid");
            }

            return finalizedInvoice;
        },
        [elements, finalizeInvoiceIfDraft, returnUrl, stripe, userInfo]
    );

    const handlePastDueInvoice = useCallback(
        async ({
            pastDueInvoice,
            selectedPaymentMethodId
        }: {
            pastDueInvoice: GetInvoiceResponse;
            selectedPaymentMethodId: string;
        }) => {
            await chargeInvoice({
                invoice: pastDueInvoice,
                selectedPaymentMethodId
            });
            await reloadSubscription([userInfo.UserId, currentSubscriptionId]);
        },
        [
            chargeInvoice,
            currentSubscriptionId,
            reloadSubscription,
            userInfo.UserId
        ]
    );

    const createEntitlement = useCallback(
        async ({
            invoice,
            selectedPaymentMethodId
        }: {
            invoice: string;
            selectedPaymentMethodId: string;
        }) => {
            const finalizedInvoice = await chargeInvoice({
                invoice,
                selectedPaymentMethodId
            });

            await client.purchaseEntitlements_PostUserPurchaseEntitlement(
                userInfo.UserId,
                finalizedInvoice.id
            );

            if (isTrialing) {
                await client.stripe_DeleteSubscription(
                    currentSubscriptionId,
                    false
                );
            }
        },
        [chargeInvoice, userInfo, isTrialing, currentSubscriptionId]
    );

    const checkout = useCallback(
        async ({
            selectedPaymentMethodId,
            planId,
            invoiceId
        }: {
            selectedPaymentMethodId: string;
            planId?: string;
            invoiceId?: string;
        }) => {
            if (!stripe || !elements) {
                return;
            }

            setIsSubmitting(true);
            setPaymentError("");

            try {
                if (selectedPaymentMethodId) {
                    const { error } = await elements.submit();

                    if (error) throw error;

                    if (selectedPaymentMethodId === "new-payment-method") {
                        const { error, setupIntent } =
                            await stripe.confirmSetup({
                                elements,
                                redirect: "if_required", // if this does redirect is caught at hooks/useCheckoutRedirect:191
                                confirmParams: {
                                    return_url: returnUrl({
                                        selectedPaymentMethodId
                                    })
                                }
                            });

                        if (error || !setupIntent) {
                            throw error;
                        }

                        const { payment_method } = setupIntent;

                        selectedPaymentMethodId =
                            typeof payment_method === "string"
                                ? payment_method
                                : payment_method.id;

                        await client.userPaymentMethods_AttachPaymentMethod(
                            userInfo.UserId,
                            selectedPaymentMethodId
                        );
                    }

                    const retriedInvoice = await retryInvoice();

                    if (retriedInvoice && !retriedInvoice.paid) {
                        await handlePastDueInvoice({
                            pastDueInvoice: retriedInvoice,
                            selectedPaymentMethodId
                        });
                    } else {
                        if (!invoiceId) {
                            await client.userPaymentMethods_SetPrimaryPaymentMethod(
                                userInfo.UserId,
                                selectedPaymentMethodId
                            );

                            await createOrUpdateSubscription({
                                selectedPaymentMethodId,
                                planId
                            });
                        } else {
                            await createEntitlement({
                                invoice: invoiceId,
                                selectedPaymentMethodId
                            });
                        }
                    }

                    onSuccess?.({ selectedPaymentMethodId });
                } else {
                    throw new Error(t("errors:no-payment-method"));
                }
            } catch (e) {
                setPaymentError(e.message || e.error.decline_code);
                onError?.(e.message || e.error.decline_code, {
                    selectedPaymentMethodId,
                    stripeError: e.error
                });
            } finally {
                setIsSubmitting(false);
            }
        },
        [
            stripe,
            elements,
            onSuccess,
            retryInvoice,
            returnUrl,
            userInfo,
            handlePastDueInvoice,
            createOrUpdateSubscription,
            createEntitlement,
            onError
        ]
    );

    return {
        checkout,
        isSubmitting,
        paymentError,
        createOrUpdateSubscription,
        createEntitlement,
        trackSubscription
    };
};
