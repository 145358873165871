import { LinkList, LinkListItem } from "components/link-list";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsTablet } from "hooks/useIsTablet";
import { useMemo } from "react";
import DefaultDisplayIcon from "assets/icons/display.svg?react";
import PlayerAppearance from "assets/icons/brandkit.svg?react";
import GatedIcon from "assets/icons/gated.svg?react";
import { useCallback } from "react";
import Eye from "assets/icons/eye.svg?react";
import styles from "./index.module.scss";
import Desktop from "assets/icons/desktop.svg?react";
import Tablet from "assets/icons/tablet.svg?react";
import Mobile from "assets/icons/mobile.svg?react";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import { parseBool } from "helpers/booleans";

export const CatalogLinkList: React.FC<{
    hasVideoPlayerAccess: boolean;
    t: (key: string) => string;
    handlePreview: (type: "desktop" | "tablet" | "mobile") => void;
}> = ({ hasVideoPlayerAccess, t, handlePreview }) => {
    const { isMobile } = useIsMobile();
    const { isTablet } = useIsTablet();
    const oneTimePassFeatureEnabled = parseBool(
        import.meta.env.VITE_ENABLE_OTP_EXPANSION
    );
    const hasPasswordProtectedContentClaim = useClaimCheck(
        "gatedcontent.password"
    );
    const hasEmailProtectedContentClaim = useClaimCheck("gatedcontent.email");

    const isGrowUser = useHasPlanRole("Grow");

    const hasExtraGatingOptions = useMemo(
        () =>
            hasEmailProtectedContentClaim ||
            hasPasswordProtectedContentClaim ||
            oneTimePassFeatureEnabled,
        [
            hasEmailProtectedContentClaim,
            hasPasswordProtectedContentClaim,
            oneTimePassFeatureEnabled
        ]
    );

    const isDesktop = useMemo(() => {
        return !isMobile && !isTablet;
    }, [isMobile, isTablet]);

    const onPreviewClick = useCallback(
        (e: React.MouseEvent, type: "desktop" | "tablet" | "mobile") => {
            e.preventDefault();
            handlePreview(type);
        },
        [handlePreview]
    );

    const LinkListAction = useMemo(() => {
        let actions: LinkListItem[] = [];

        if (hasVideoPlayerAccess) {
            actions.push({
                Title: t("catalog-page:featured-trailer"),
                Icon: <DefaultDisplayIcon />,
                NavigateTo: "/catalog/featured-trailer"
            });
        }

        if (hasVideoPlayerAccess) {
            actions.push({
                Title: hasExtraGatingOptions
                    ? t("catalog-page:gating-options")
                    : t("catalog-page:subscription-options"),
                Icon: <GatedIcon />,
                NavigateTo: "/catalog/catalog-subscription"
            });
        }

        actions.push({
            Title: t("catalog-page:customization"),
            Icon: <PlayerAppearance />,
            NavigateTo: "/catalog/customization"
        });

        const previewLink = {
            Title: t("catalog-page:preview"),
            Description: isMobile
                ? null
                : t("catalog-page:preview-description"),
            Icon: <Eye />,
            NavigateTo: null,
            OnClick: isMobile ? (e) => onPreviewClick(e, "mobile") : null,
            Child: !isMobile && (
                <div className={styles["segmented-button"]}>
                    {isDesktop && (
                        <button
                            className={styles["segmented-button-option"]}
                            onClick={(e) => onPreviewClick(e, "desktop")}
                        >
                            <Desktop />
                            {t("catalog-page:desktop")}
                        </button>
                    )}
                    {(isTablet || isDesktop) && (
                        <button
                            className={styles["segmented-button-option"]}
                            onClick={(e) => onPreviewClick(e, "tablet")}
                        >
                            <Tablet />
                            {t("catalog-page:tablet")}
                        </button>
                    )}
                    <button
                        className={styles["segmented-button-option"]}
                        onClick={(e) => onPreviewClick(e, "mobile")}
                    >
                        <Mobile />
                        {t("catalog-page:mobile")}
                    </button>
                </div>
            )
        };
        if (isGrowUser) {
            actions.unshift(previewLink);
        } else {
            actions.push(previewLink);
        }

        return actions;
    }, [
        hasVideoPlayerAccess,
        t,
        isMobile,
        isDesktop,
        isTablet,
        hasExtraGatingOptions,
        onPreviewClick,
        isGrowUser
    ]);

    return (
        <>
            <LinkList actions={LinkListAction} />
        </>
    );
};
