import React, { useMemo } from "react";
import styles from "./index.module.scss";
import Checkmark from "../../assets/icons/checkmark-simple.svg?react";

export const RadioCardsContainer = ({ children }) => (
    <div className={styles["radio-cards-container"]}>{children}</div>
);

export const RadioCardsBlockContainer = ({ children }) => (
    <div className={`${styles["radio-cards-container"]} ${styles["block"]}`}>
        {children}
    </div>
);

export interface RadioCardProps<T> {
    id: T;
    content: JSX.Element | string;
    onSelect: (id: T) => void;
    selectedCardId: T;
    name: string;
    expandedContent?: JSX.Element | string;
    checkboxPosition?: "start" | "end";
    /** Removes radio button from selected card and hides unselected card(s) */
    selectionLocked?: boolean;
}

export const RadioCard = <T,>({
    id,
    content,
    onSelect,
    selectedCardId,
    name,
    expandedContent,
    checkboxPosition = "end",
    selectionLocked
}: RadioCardProps<T>) => {
    const hideCard = useMemo(
        () => selectionLocked && selectedCardId !== id,
        [id, selectedCardId, selectionLocked]
    );
    const hideRadioButton = useMemo(
        () => selectionLocked && selectedCardId === id,
        [id, selectedCardId, selectionLocked]
    );

    return !hideCard ? (
        <div
            className={`${styles["radio-card"]} ${
                selectedCardId === id ? styles["checked"] : ""
            }`}
            onClick={() => onSelect(id)}
            onKeyDown={(e) => {
                if (e.key === "Enter") onSelect(id);
            }}
        >
            <div className={styles["primary-content"]}>
                <div className={styles["radio-card-content"]}>{content}</div>
                {!hideRadioButton && (
                    <div
                        className={`${styles["radio-card-checkbox-container"]} ${styles[checkboxPosition]}`}
                    >
                        <input
                            type="radio"
                            id={id as string}
                            value={id as string}
                            name={name}
                            className={styles["radio-card-checkbox"]}
                            checked={selectedCardId === id}
                            readOnly
                        />
                        <Checkmark className={styles["radio-checkbox-icon"]} />
                    </div>
                )}
            </div>

            <div
                className={
                    selectedCardId === id
                        ? styles["expanded-content"]
                        : styles["expanded-hidden"]
                }
            >
                {expandedContent}
            </div>
        </div>
    ) : (
        <></>
    );
};
