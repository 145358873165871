import React, { useMemo } from "react";
import {
    CreatorProductVideoPlayerEntitlement,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";
import { Badge } from "../types";
import GatedIcon from "assets/icons/gated.svg?react";
import _LockIcon from "assets/icons/lock.svg?react";
import styles from "../index.module.scss";
import { useTranslation } from "react-i18next";

interface PlayerBadgesProps {
    player: VideoPlayer;
    entitlement: CreatorProductVideoPlayerEntitlement;
}

const LockIcon: React.FC = () => <_LockIcon width={12} height={12} />;

export const PlayerBadges: React.FC<PlayerBadgesProps> = ({
    player,
    entitlement
}) => {
    const { t } = useTranslation();

    const playerBadges = useMemo<Badge[]>(() => {
        const badges: Badge[] = [];
        if (entitlement) {
            badges.push({
                slug: entitlement.Product.Name,
                icon: <GatedIcon />
            });
        }

        if (
            player.IsEmailGatingEnabled &&
            player.IsPasswordGatingEnabled &&
            !entitlement
        )
            badges.push({
                slug: t("video-badges:email-and-password"),
                icon: <LockIcon />
            });
        else if (player.IsEmailGatingEnabled && !entitlement)
            badges.push({
                slug: t("video-badges:email"),
                icon: <LockIcon />
            });
        else if (player.IsPasswordGatingEnabled)
            badges.push({
                slug: t("video-badges:password"),
                icon: <LockIcon />
            });
        return badges;
    }, [entitlement, player, t]);

    return (
        <>
            {playerBadges.length > 0 && (
                <div
                    className={`badges-container ${styles["badges-container"]}`}
                >
                    {playerBadges.map((badge) => {
                        return (
                            <div
                                className={` ${styles["badge"]} ${
                                    styles[`badge-${badge.slug}`]
                                }`}
                                title={badge.slug}
                                key={badge.slug}
                            >
                                {badge.icon}
                                <p>{badge.slug}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
